import { useState } from "react";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowLeftCircleFill,
} from "react-icons/bs";

interface ICarouselProps {
  slides: string[];
}

const Carousel: React.FC<ICarouselProps> = (props: ICarouselProps) => {
  let [current, setCurrent] = useState(0);

  let previousSlide = () => {
    if (current === 0) setCurrent(props.slides.length - 1);
    else setCurrent(current - 1);
  };

  let nextSlide = () => {
    if (current === props.slides.length - 1) setCurrent(0);
    else setCurrent(current + 1);
  };

  return (
    <div className="overflow-hidden relative">
      <div
        className={`flex transition ease-out duration-400`}
        style={{ transform: `translateX(-${current * 100}%)` }}
      >
        {props.slides.map((src: string, idx: number) => {
          return <img key={idx} src={src} alt={src.toString()}></img>;
        })}
      </div>
      <div className="absolute top-0 h-full w-full flex justify-between item-center text-white px-10 text-3xl">
        <button onClick={previousSlide}>
          <BsFillArrowLeftCircleFill />
        </button>
        <button onClick={nextSlide}>
          <BsFillArrowRightCircleFill />
        </button>
      </div>

      <div className="absolute bottom-0 py-4 flex justify-center gap-3 w-full">
        {props.slides.map((s, i) => {
          return (
            <div
              onClick={() => {
                setCurrent(i);
              }}
              key={"circle" + i}
              className={`rounded-full w-5 h-5 cursor-pointer  ${
                i === current ? "bg-white" : "bg-gray-500"
              }`}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;
