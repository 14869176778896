const Contact = () => (
  <section id="contact" className="bg-h-blue">
    <div className="max-w-7xl rounded-2xl mx-auto p-4 sm:p-6 lg:p-8">
      <div className="mb-16 text-center">
        <h3 className="font-black mt-2 pb-4 text-5xl lg:text-7xl font-bold tracking-tight text-white">
          Contact Us
        </h3>
        <p className="text-white leading-loose font-black">
          Tel:{" "}
          <a className="text-white leading-loose" href="https://wa.link/4ryenn">
            +971 058 586 4996
          </a>
        </p>
        <p className="text-white leading-loose font-black">
          Email:{" "}
          <a
            className="text-white leading-loose"
            href="mailto:Projects@HProjects.me"
          >
            Projects@HProjects.me
          </a>
        </p>
        <p className="font-black text-white leading-loose">
          Address:{" "}
          <a
            className="text-white leading-loose font-black"
            href="https://maps.app.goo.gl/cghCBpkD2Xmd9Eny7"
          >
            Office 202, Building C, Al Saaha offices, Souk Al Bahar, Downtown
            Dubai
          </a>
        </p>
      </div>
    </div>
  </section>
);

export default Contact;
