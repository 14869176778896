const Design = () => {
  return (
    <div id="/" className="w-screen mx-auto py-16 px-14 sm:px-6 lg:px-8 h-fit">
      <div className="flex flex-col justify-center items-center">
        {/* <img
          src="images/full-logo.png"
          alt="Full Logo"
          className="w-min-full"
        /> */}
        <h1 className="font-black p-4 rounded-lg text-[#004AAD] font-bold text-4xl md:text-5xl lg:text-8xl text-center">
          Design Section
        </h1>
      </div>
    </div>
  );
};

export default Design;
