const About = () => (
  <section className="lg:py-28 pt-28 overflow-hidden" id="about">
    <div className="max-w-7xl mx-auto rounded-2xl p-4 sm:p-6 lg:p-8 bg-white">
      <div className="mb-16 text-center">
        <h3 className="mt-2 pb-4 text-5xl lg:text-7xl font-bold tracking-tight text-[#004AAD]">
          About Us
        </h3>
        <div className="bg-h-blue rounded-lg flex flex-col items-center p-10">
          <img
            src="https://hprojectswebcontainerci.blob.core.windows.net/images/Team/PHOTO-2024-03-05-15-45-59.jpg"
            alt="Team"
            className="w-min-full"
          ></img>

          <div className="bg-gray-900 rounded-lg flex flex-column"></div>
          <p className="mt-4 text-left font-black leading-snug text-white">
            Welcome to H Projects, your premier destination for top-tier project
            management consultancy services in Dubai. With a steadfast
            commitment to excellence, we specialize in providing comprehensive
            solutions tailored to the unique needs of the hospitality,
            commercial property shop fitting, and sustainable painting
            industries.
          </p>
          <p className="mt-4 font-black text-left text-white">
            At H Projects, we understand the intricacies of project management
            in these specialized sectors, and our team of experts is dedicated
            to ensuring the success of every endeavor we undertake. With years
            of experience and a proven track record of delivering exceptional
            results, we pride ourselves on our ability to exceed client
            expectations and drive project success.
          </p>
          <p className="mt-4 font-black text-left  text-white">
            Our range of services encompasses all aspects of project management,
            from initial planning and design to execution and beyond. Whether
            you&apos;re embarking on a new hospitality venture, renovating a
            commercial property, or seeking sustainable painting solutions, we
            have the expertise and resources to guide you every step of the way.
          </p>
          <p className="mt-4 font-black text-left text-white">
            What sets us apart is our unwavering commitment to excellence,
            sustainability, and innovation. We strive to incorporate the latest
            technologies and best practices into our projects, ensuring optimal
            efficiency, cost-effectiveness, and environmental responsibility.
          </p>
          <p className="mt-4 font-black text-left text-white">
            At H Projects, we believe in building lasting relationships with our
            clients based on trust, transparency, and integrity. Our team works
            tirelessly to understand your unique goals and challenges, providing
            personalized solutions that align with your vision and objectives.
          </p>
          <p className="mt-4 font-black text-left text-white">
            With H Projects, you can rest assured that your project is in
            capable hands. Contact us today to learn more about how we can help
            bring your vision to life and achieve unparalleled success in the
            hospitality, commercial property shop fitting, and sustainable
            painting industries.
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default About;
