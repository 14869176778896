interface IButton {
  primary?: boolean;
  children: React.ReactNode;
  modifier?: string;
  link: string;
}

const Button = ({ primary, modifier, children, link, ...rest }: IButton) => {
  const baseStyle = `font-black py-2 px-4 border rounded`;
  const styles = primary
    ? `bg-h-blue text-white border-h-blue hover:bg-h-blue`
    : `bg-white text-ch-blue border-ch-blue hover:bg-h-blue`;

  return (
    <>
      <a href={link}>
        <button
          type="button"
          className={`${baseStyle} ${styles} ${modifier ?? ``}`}
          {...rest}
        >
          {children}
        </button>
      </a>
    </>
  );
};

export default Button;
