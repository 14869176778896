const Header = () => {
  return (
    <div
      id="/"
      className="bg-hero-pattern bg-cover bg-no-repeat h-screen mx-auto py-16 px-14 sm:px-6 lg:px-8"
    >
      <div className="flex flex-col justify-center items-center">
        {/* <img
          src="images/full-logo.png"
          alt="Full Logo"
          className="w-min-full rounded-lg"
          width={800}
          height={200}
        /> */}
        <h1 className="bg-h-blue font-black p-4 rounded-lg text-white font-bold text-4xl md:text-5xl lg:text-8xl text-center">
          H Projects
        </h1>
        <h1 className="bg-h-blue p-4 rounded-lg text-white font-bold text-4xl md:text-5xl lg:text-8xl text-center leading-snug">
          Collaboration Creates...
        </h1>
      </div>
    </div>
  );
};

export default Header;
